











import { Component, Vue } from "vue-property-decorator";
import Header from "@/common-app/components/Header.vue";
import Footer from "@/common-app/components/Footer.vue";
import Chat from "@/common-app/components/Chat.vue";

@Component({
  components: {
    Header,
    Footer,
    Chat
  }
})
export default class WebsiteLayout extends Vue {}
